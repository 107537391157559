import React from 'react';

// UTILS
import { formatAddress } from '../../utils/format-address';

// STYLES
import './styles.scss';

// ASSETS
import LinkOut from '../../assets/img/link_out.svg';

function TransactionHash({ txHash }: { txHash: string }) {
    return (
        <div className="transaction-hash-content">
            txn:
            <a className="transaction-address-link" href={`${process.env.REACT_APP_ETHERSCAN}/tx/${txHash}`} target="_blank">
                <span className="transaction-address">{txHash ? formatAddress(txHash) : '...'}</span>
                <img src={LinkOut} />
            </a>
        </div>
    );

}

export default TransactionHash;