import React from 'react';
import { useNavigate } from 'react-router';

// ANTD
import Button from 'antd/lib/button';

// MODULES
import VideoContainer from '../../VideoContainer';

// STYLES
import './styles.scss';

function NoItems() {

    const navigateTo = (route: string) => {
        window.open(route, '_blank');
    };

    return (
        <div className="no-items-container">
            <div className="no-items-content">
                <div className="no-items-video-container">
                    <VideoContainer size={300} boxType={3} autoplay={true} />
                </div>
                <div className="no-items-content">
                    <p>You have no DGFamily Boxes.<br />
                        You can acquire a Box on the secondary market.</p>
                </div>
                <div className="no-items-actions">
                    <Button type="primary" size="large" onClick={() => navigateTo('https://opensea.io/collection/dolce-gabbana-dgfamily-glass')}>Opensea</Button>
                    <Button type="primary" size="large" onClick={() => navigateTo('https://unxd.com/marketplace')}>UNXD Market</Button>
                </div>
            </div>
            <div className="reveal-items-actions-container">
                <div className="reveal-items-actions-content">
                <span className="view-revealed-boxes" onClick={() => navigateTo('/revealed-boxes')}>View Revealed Boxes</span>
                </div>
            </div>
        </div>
    );

}

export default NoItems;