import React, { LegacyRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

// STYLES
import './styles.scss';

function VideoContainer(
    {
        videoRef,
        size,
        autoplay = false,
        boxType,
        onVideoEnd = false,
        loop = true,
        framePath = 'frame.jpg'
    }: {
        videoRef?: React.MutableRefObject<HTMLVideoElement | undefined>,
        size?: number,
        autoplay?: boolean,
        boxType: number,
        onVideoEnd?: boolean,
        loop?: boolean,
        framePath?: string,
    }) {

    const navigate = useNavigate();
    const location = useLocation();
    const [videoSrc, setVideoSrc] = useState<string>();

    const getVideoContainerHeight = (size: number | undefined): void => {
        if (!size) {

            const videoContainer = document.getElementById('video-container-id') as HTMLElement;

            if (videoContainer?.clientHeight > videoContainer?.clientWidth) {
                size = videoContainer?.clientWidth;
            } else {
                size = videoContainer?.clientHeight
            }
        }

        if (location.pathname === '/glass-boxes/revealed-items' || location.pathname === '/revealed-boxes') {
            const videoByReference = getRevealedVideoByReference(boxType, size) as string;
            setVideoSrc(videoByReference);
        } else {
            const videoByReference = getVideoByReference(boxType, size) as string;
            setVideoSrc(videoByReference);
        }

    };

    const onVideoEnded = (): void => {
        navigate(`/glass-boxes/revealed-items`);
    };

    useEffect(() => {
        getVideoContainerHeight(size);
    }, []);

    return (
        <div className="no-items-video-container">
            <div id="video-container-id" className="video-container">
                {videoSrc ? (
                    <video ref={videoRef as LegacyRef<HTMLVideoElement>} poster={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/${framePath}`} loop={loop} autoPlay={autoplay} onEnded={() => onVideoEnd && onVideoEnded()} preload="none" playsInline muted>
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                ) : null}
            </div>
        </div>
    );
}

function getVideoByReference(boxType: number, size: number): string | void {

    // BLACK REVEAL
    if (boxType === 0) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto/v1652941594/UNXD/Black_Box_Reveal_White_BG_qociqs.mp4`;

    // GOLD REVEAL
    if (boxType === 1) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto/v1652941594/UNXD/Gold_Box_Reveal_White_BG_pawgqp.mp4`;

    // PLATINUM REVEAL
    if (boxType === 2) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto/v1652941594/UNXD/Platinum_Box_Reveal_White_BG_yjoaff.mp4`;

    // LOADER 
    if (boxType === 3) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto,w_${size}/v1651761818/UNXD/UNXD_x_DG_DGFamily_Box_Glass_Transparent_BG_n6q7hs.mp4`

    // GENERIC REVEAL
    if (boxType === 5) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto/v1652941593/UNXD/No_Box_Reveal_White_BG_xeaxcl.mp4`;

    // TRANSPARENT BOX
    if (boxType === 6) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto/v1652950108/UNXD/DG_Wireframe_WEB_white_2_yn79a9.mp4`;

}

function getRevealedVideoByReference(boxType: number, size: number): string | void {

    // BLACK REVEAL
    if (boxType === 0) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,q_auto,h_${size}/v1652966701/UNXD/Black_Transparent_BG_2_eaiwx5.mp4`;

    // GOLD REVEAL
    if (boxType === 1) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,q_auto,h_${size}/v1652966700/UNXD/gold_Transparent_BG_1_iokgvc.mp4`;

    // PLATINUM REVEAL
    if (boxType === 2) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,q_auto,h_${size}/v1652966698/UNXD/platinum_Transparent_BG_v3ryl3.mp4`;

    // LOADER 
    if (boxType === 3) return `https://res.cloudinary.com/dvz8qqgob/video/upload/c_scale,f_auto,h_${size},q_auto,w_${size}/v1651761818/UNXD/UNXD_x_DG_DGFamily_Box_Glass_Transparent_BG_n6q7hs.mp4`

}

export default VideoContainer;