import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

// ANTD
import { Button } from 'antd';

// MODULES
import VideoContainer from '../VideoContainer';

// STYLES
import './styles.scss';

function Home() {

    const navigate = useNavigate();

    const approval = (): void => {
        navigate('/glass-boxes/approval');
    };

    useEffect(() => {
        localStorage.removeItem('txInfo');
    }, []);

    return (
        <div className="home-container">
            <div className="home-content">
                <div className="home-video-container">
                    <VideoContainer size={450} boxType={3} autoplay={true} />
                </div>
                <div className="home-content-container">
                    <h1>DGFamily Glass Box Reveal</h1>
                    <p>Welcome to the DGFamily!</p>
                    <p>This process will reveal the true identity of your DGFamily Glass Box. Powered by Chainlink VRF, the on-chain reveal process ensures which DGFamily Box you receive (Black, Gold, or Platinum) is provably randomly determined.</p>
                    <p>To reveal your DGFamily Glass Box, you will need to fully complete three steps. Please note: this process can only be completed once for each Glass Box and is not reversible.</p>
                    <h3>The steps:</h3>
                    <ol>
                        <li>Approve the DGFamily Reveal contract.</li>
                        <li>Select which Glass Boxes you would like to reveal.</li>
                        <li>Click “Reveal” to initiate the reveal process and uncover the true identity of your DGFamily Glass Box.</li>
                    </ol>
                    <div className="home-actions">
                        <Button type="primary" size="large" onClick={() => approval()}>Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;