import React from 'react';
import { Routes, Route } from "react-router-dom";

// MODULES
import Approval from '../Approval';
import Home from '../Home';
import Select from '../Select';
import Reveal from '../Reveal';
import RevealedItemVideo from '../RevealedItemVideo';
import RevealedItems from '../RevealedItems'

// STYLES
import './styles.scss';

function RevealItems() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/approval" element={<Approval />} />
            <Route path="/select" element={<Select />} />
            <Route path="/reveal" element={<Reveal />} />
            <Route path="/revealed-items" element={<RevealedItems />} />
            <Route path="/:itemId" element={<RevealedItemVideo />} />
        </Routes>
    );
}

export default RevealItems;
