import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// STATE
import { useGlobalState } from '../index';

// MODULES
import VideoContainer from '../VideoContainer';

// STYLES
import './styles.scss';

export const FRAME_PATH = 'reveal-frame.jpg';

function RevealedItemVideo() {

    const navigate = useNavigate();
    const [state, dispatch] = useGlobalState();
    const { itemId } = useParams<{ itemId: string }>();

    const closeVideo = () => {
        navigate(`/glass-boxes/revealed-items`);
    };

    const checkDgBoxes = (): void => {
        if (!state.dgBoxes.length) navigate('/glass-boxes/select');
    };

    useEffect(() => {
        checkDgBoxes();
    }, []);

    return (
        <div className="revealed-item-container">
            <div className="revealed-video-container">
                {/* <CloseOutlined className="close-icon" onClick={() => closeVideo()} /> */}
                <VideoContainer boxType={parseInt(itemId as string)} autoplay={true} onVideoEnd={true} loop={false} framePath={FRAME_PATH} />
            </div>
        </div>
    );

}

export default RevealedItemVideo;
