import React, { useEffect, useRef, useState } from 'react';

// STATE
import { useGlobalState } from '../../index';

// MODULES
import VideoContainer from '../../VideoContainer';

// STYLES
import './styles.scss';

export const SELECT_BOXES_NUM = 10;

function RevealItem({ boxToReveal, boxType, size, framePath }: { boxToReveal: number, boxType: number, size: number, framePath?: string }) {

    const [state, dispatch] = useGlobalState();
    const [revealItemState, setRevealItemState] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>();

    const revealItem = (boxId: number): void => {

        const claimBoxes = state.claimBoxes;
        const index = claimBoxes.indexOf(boxId);

        if (index === -1 && claimBoxes.length < SELECT_BOXES_NUM) {
            claimBoxes.push(boxId);
            setRevealItemState(true);
        } else if (index !== -1) {
            claimBoxes.splice(index, 1);
            setRevealItemState(false);
        }

        dispatch({ claimBoxes });

    };

    const setBoxState = (boxToReveal: number): void => {
        const selectedBoxes = state.claimBoxes;
        const index = selectedBoxes.indexOf(boxToReveal);
        if (index !== -1) setRevealItemState(true);
    };

    const handlePlay = (): void => {
        videoRef.current && videoRef.current.play();
    };

    const handleStop = (): void => {
        videoRef.current && videoRef.current.pause();
        if (videoRef.current) videoRef.current.currentTime = 0;
    };

    useEffect(() => {
        setBoxState(boxToReveal);
    }, []);

    return (
        <div className={`box-reveal-item-container ${revealItemState === true ? 'active' : ''}`} onMouseEnter={() => handlePlay()} onMouseLeave={() => handleStop()} onClick={() => revealItem(boxToReveal)}>
            <div className="reveal-video-container">
                <VideoContainer videoRef={videoRef} size={size} boxType={boxType} framePath={framePath} />
            </div>
            <div className="revel-item-box-id">Glass Box #{boxToReveal}</div>
        </div>
    );

}

export default RevealItem;