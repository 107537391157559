import React, { useRef, useState } from 'react';

// STATE
import { DgBox, useGlobalState } from '../../index';

// MODULES
import VideoContainer from '../../VideoContainer';

// STYLES
import './styles.scss';

function RevealedItem({ dgBox, size }: { dgBox: DgBox, size: number }) {

    const [revealItemState, setRevealItemState] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>();

    const handlePlay = (): void => {
        videoRef.current && videoRef.current.play();
    };

    const handleStop = (): void => {
        videoRef.current && videoRef.current.pause();
        if (videoRef.current) videoRef.current.currentTime = 0;
    };

    return (
        <div className={`box-revealed-item-container ${revealItemState === true ? 'active' : ''}`} onMouseEnter={() => handlePlay()} onMouseLeave={() => handleStop()}>
            <div className="revealed-video-container">
                <VideoContainer videoRef={videoRef} size={size} boxType={dgBox.boxType} framePath={dgBox.frame} />
            </div>
            <div className="reveled-item-box-id">DGFamily Box #{dgBox.dgTokenId}</div>
        </div>
    );

}

export default RevealedItem;