import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// PROVIDER
import { getProvider } from '../provider';

// MODULES
import VideoContainer from '../VideoContainer';

// STYLES
import './styles.scss';

// ABIS
const abi = require('../assets/abis/DGFamilyReveal.json');

function Inactive() {

    const navigate = useNavigate();

    const getInactiveState = async (): Promise<void> => {
        const provider = await getProvider();
        const contract = new provider.eth.Contract(abi.abi, process.env.REACT_APP_CONTRACT_REVEAL);
        const revealPhaseStarted = await contract.methods.revealPhaseStarted().call();
        if (revealPhaseStarted) navigate('/');
    };

    useEffect(() => {
        getInactiveState();
    }, []);

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="loader-video-container">
                    <VideoContainer size={300} boxType={3} autoplay={true} />
                </div>
                <h1>DGFamily Glass Box Reveal</h1>
                <p>Reveal is not currently live.</p>
            </div>
        </div>
    );
};

export default Inactive;