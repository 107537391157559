import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// WEB3
import Web3 from "web3";

// MODULES
import App from './App';

// STYLES
import './index.scss';

declare global {
  interface Window {
    ethereum: any;
    web3: Web3;
  }
};

export interface DgBox {
  boxType: number;
  dgTokenId: number;
  frame: string;
};

export interface User {
  _id: string;
  username: string;
  profileImage: string;
  walletAddress: string;
};

export interface GlobalState {
  chainId: number | undefined;
  connected: boolean;
  isApproved: boolean;
  user: string | null;
  claimBoxes: number[];
  dgBoxes: DgBox[];
  revealTxHash: string | null;
  gasEstimate: number | null;
  provider: Web3 | null;
};

const defaultGlobalState: GlobalState = {
  chainId: undefined,
  connected: false,
  isApproved: false,
  user: null,
  claimBoxes: [],
  dgBoxes: [],
  revealTxHash: null,
  gasEstimate: null,
  provider: null,
};

const GlobalStateContext = React.createContext(defaultGlobalState);
const DispatchStateContext = React.createContext<any>(undefined);

type GlobalProviderProps = { children: React.ReactNode };

const GlobalStateProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    defaultGlobalState
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>
        {children}
      </DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext),
];

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <App />
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
