import React from 'react';

// MODULES
import VideoContainer from '../../VideoContainer';

// STYLES
import './styles.scss';

function Loader({ title, content }: { title: string, content: string }) {

    return (
        <div className="loader-content">
            <h1>{title}</h1>
            <div className="loader-video-container">
                <VideoContainer size={300} boxType={3} autoplay={true} />
            </div>
            <div className="messages-container">
                <span>{content}</span>
            </div>
        </div>
    );
}

export default Loader;