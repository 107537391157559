import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

// PROVIDER
import { getProvider } from '../../provider';

// ANTD
import Button from 'antd/lib/button';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';

// UTILS
import { formatAddress } from '../../utils/format-address';

function Header() {

    const [state, dispatch] = useGlobalState();

    const connectWallet = async () => {
        const accounts = await window.ethereum?.request({
            method: "eth_requestAccounts",
        });
        if (accounts?.length) dispatch({ user: accounts[0], connected: true });
    };

    return (
        <div className="header-container">
            <div className="header-content">
                <img src="../../assets/img/logo_dark.png" width="111px" alt="UNXD Logo" />
                <ul>
                    <li><a href="https://unxd.com/drops">Drops</a></li>
                    <li><a href="https://unxd.com/marketplace">Marketplace</a></li>
                </ul>
                {state.connected ? (
                    <Button type="primary" className="connected">{formatAddress(state.user)}</Button>
                ) : (
                    <Button type="primary" className="connect" onClick={() => connectWallet()} disabled={state.chainId !== parseInt(process.env.REACT_APP_NETWORK_ID as string)}>Connect Wallet</Button>
                )}
            </div>
        </div>
    );

}

export default Header;