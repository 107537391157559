import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

// STATE
import { useGlobalState } from './index';

// PROVIDER
import { getProvider } from './provider';

// MODULES
import Header from './shared/Header';
import Home from './Home';
import Inactive from './Inactive';
import Login from './Login';
import RevealedBoxes from './RevealedBoxes';
import RevealItems from './RevealItems';
import RevealedItems from './RevealedItems';

// STYLES
import './App.scss';

// ABIS
const abi = require('./assets/abis/DGFamilyReveal.json');

function App() {

  const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();

  const checkNetworkId = async (): Promise<void> => {
    const provider = await getProvider();
    const chainId = await provider.eth.getChainId();
    if (chainId !== parseInt(process.env.REACT_APP_NETWORK_ID as string)) {
      dispatch({ connected: false });
      navigate('/login');
    } else {
      dispatch({ chainId });
      getAccount();
      getInactiveState();
    }
  };

  const getInactiveState = async (): Promise<void> => {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(abi.abi, process.env.REACT_APP_CONTRACT_REVEAL);
    const revealPhaseStarted = await contract.methods.revealPhaseStarted().call();
    if (!revealPhaseStarted) navigate('/inactive');
  };

  const getAccount = async (): Promise<void> => {

    const provider = await getProvider();
    const accounts = await provider.eth.getAccounts();

    if (accounts.length) {
      dispatch({ user: accounts[0], connected: true });
    } else {
      dispatch({ user: null, connected: false });
      navigate('/login');
    }

  };

  const listenNetworkChange = () => {
    window.ethereum?.on('chainChanged', (chainId: string) => {
      dispatch({ chainId: parseInt(chainId) });
    });
  };

  const checkProvider = async () => {
    await checkNetworkId();
    await getAccount();
  }

  useEffect(() => {
    checkNetworkId();
  }, [state.chainId]);

  useEffect(() => {
    listenNetworkChange();
    window.ethereum && checkProvider();
  }, [window.ethereum]);

  return (
    <div className="app-container">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/glass-boxes/*" element={<RevealItems />} />
        <Route path="/dgfamily-boxes" element={<RevealedItems />} />
        <Route path="/login" element={<Login />} />
        <Route path="/revealed-boxes" element={<RevealedBoxes />} />
        <Route path="/inactive" element={<Inactive />} />
      </Routes>
    </div>
  );

}

export default App;