import React from 'react';
import { useLocation } from 'react-router-dom';

import './styles.scss';

function Stepper() {

    const location = useLocation();

    return (
        <div className="stepper-router-container">
            <div className="stepper-router-content">
                <ul className="steps">
                    <li className={`circle ${location.pathname === '/glass-boxes/approval' ? 'active' : ''} ${location.pathname === '/glass-boxes/reveal' || location.pathname === '/glass-boxes/select' || location.pathname === '/glass-boxes/revealed-items' ? 'done' : ''}`}>
                        <span className="step-desc">Approve</span>
                    </li>
                    <li className={`line ${location.pathname === '/glass-boxes/revealed-items' || location.pathname === '/glass-boxes/select' || location.pathname === '/glass-boxes/reveal' ? 'done' : ''}`}></li>
                    <li className={`circle ${location.pathname === '/glass-boxes/select' ? 'active' : ''} ${location.pathname === '/glass-boxes/reveal' || location.pathname === '/glass-boxes/revealed-items' ? 'done' : ''}`}>
                        <span className="step-desc">Select</span>
                    </li>
                    <li className={`line ${location.pathname === '/glass-boxes/reveal' || location.pathname === '/glass-boxes/revealed-items' ? 'done' : ''}`}></li>
                    <li className={`circle ${location.pathname === '/glass-boxes/reveal' ? 'active' : ''} ${location.pathname === '/glass-boxes/revealed-items' ? 'done' : ''}`}>
                        <span className="step-desc">Reveal</span>
                    </li>
                </ul>
            </div>
            <div className="stepper-shadow"></div>
        </div>
    );

}

export default Stepper;