import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// WEB3
import { getProvider } from '../provider';

// STATE
import { useGlobalState } from '../index';

// ANTD
import Button from 'antd/lib/button';

// MODULES
import VideoContainer from '../VideoContainer';

// STYLES
import './styles.scss';

export function Login() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const checkLogin = async (): Promise<void> => {
        if (state.connected) navigate('/');
    };

    const connectWallet = async () => {
        const accounts = await window.ethereum?.request({
            method: "eth_requestAccounts",
        });
        if (accounts?.length) dispatch({ user: accounts[0], connected: true });
    };

    useEffect(() => {
        checkLogin();
    }, [state.connected])

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="loader-video-container">
                    <VideoContainer size={300} boxType={3} autoplay={true} />
                </div>
                <h1>DGFamily Glass Box Reveal</h1>
                <p>To reveal your DGFamily Glass Box, you will need to be logged in to MetaMask and connected to the Ethereum mainnet.</p>
                <div className="login-actions">
                    <Button type="primary" size="large" disabled={state.chainId !== parseInt(process.env.REACT_APP_NETWORK_ID as string)} onClick={() => connectWallet()}>Connect Wallet</Button>
                </div>
            </div>
        </div>
    );

}

export default Login;